<template>
  <div class="cont">
    <div class="login-box">
      <div class="title-txt">
        <div class="tab-logo">
          <img src="@/assets/logo.png" alt="" srcset="">
        </div>
        <div v-text="$t('msg.forget_pwd')">忘记密码</div>
       </div>
      <el-form class="form-box" auto-complete="on">
        <el-form-item label="" prop="mobile">
          <el-input prefix-icon="el-icon-mobile-phone" :placeholder="$t('msg.inp_mobile_num')" v-model="mobile" oninput="value=value.replace(/[^\d]/g,'')" maxLength='11' clearable></el-input>
        </el-form-item>
        <div>
          <el-form-item label="" prop="codeVal">
            <el-input :placeholder="$t('msg.ver_code')" class="code-inp" v-model="codeVal" oninput="value=value.replace(/[^\d]/g,'')" maxLength='6' clearable></el-input>
            <el-button type="primary" class="code-btn" id="code" @click="get_mobile_code()" :disabled="codeDisabled" v-text="$t('msg.get_code')"></el-button>
          </el-form-item>
        </div>
        <el-form-item label="" prop="newPwd">
          <el-input prefix-icon="el-icon-lock" type="password" :placeholder="$t('msg.inp_new_pwd')" v-model="newPwd" clearable></el-input>
        </el-form-item>
        <el-form-item label="" prop="confirmPwd">
          <el-input prefix-icon="el-icon-lock" type="password" :placeholder="$t('msg.inp_confirm_pwd')" v-model="confirmPwd" clearable></el-input>
        </el-form-item>
        <div class="btn-box">
          <el-button type="primary" @click="modify()" v-text="$t('msg.immed_modify')">立即修改</el-button>
          <el-button type="primary" @click="reset()" v-text="$t('msg.reset')">重置</el-button>
        </div>
      </el-form>
      <div class="txt-box">
        <a href="javascript:;" @click="code_login" v-text="$t('msg.acc_login')">帐号登录</a>
      </div>
    </div>
  </div>
</template>
<script>
import { urlObj } from '@/api/interface'
import common from '@/common/js/common'
export default {
  data () {
    return {
      mobile: '', // 手机号
      codeVal: '', // 验证码
      codeDisabled: false, // 按钮状态
      newPwd: '', // 新密码
      confirmPwd: '', // 确认新密码
      time: '', // 定时器
      confirm: '确定',
      get_code: '获取验证码',
      reacquire: ' 秒后重新获取',
      inp_mobile: '请输入手机号！',
      mobile_error: '手机号格式错误！',
      pwd_len_6_16: '密码长度在 6 到 16 个字符！',
      pwd_inconsistent: '两次密码不一致！',
      update_succ_jump_login: '修改密码成功，即将跳转到登录！'
    }
  },
  methods: {
    // 获取手机验证码
    get_mobile_code () {
      if (!this.mobile) {
        this.$alert(this.inp_mobile, '', {
          confirmButtonText: this.confirm,
          type: 'error'
        })
        return
      }
      const regMobile = /^[1][34578]\d{9}$/
      if (!regMobile.test(this.mobile)) {
        this.$alert(this.mobile_error, '', {
          confirmButtonText: this.confirm,
          type: 'error'
        })
        return
      }
      let codUrl = urlObj.site + urlObj.getMobileCode
      let codParam = {
        mobile: this.mobile
      }
      this.$axios.post(codUrl, codParam).then((res) => {
        if (res.msg === 'success') {
          // console.log(res)
          this.codeDisabled = true
          let second = 60
          this.time = setInterval(() => {
            second = second - 1
            if (second <= 0) {
              this.codeDisabled = false
              window.clearTimeout(this.time)
            } else {
              this.dialogCode = false
            }
          }, 1000)
        }
      })
    },
    // 立即修改
    modify () {
      if (this.newPwd.length < 6 || this.newPwd.length > 16 || this.confirmPwd.length < 6 || this.confirmPwd.length > 16) {
        this.$alert(this.pwd_len_6_16, '', {
          confirmButtonText: this.confirm,
          type: 'error'
        })
        return
      }
      if (this.newPwd !== this.confirmPwd) {
        this.$alert(this.pwd_inconsistent, '', {
          confirmButtonText: this.confirm,
          type: 'error'
        })
        return
      }
      let codUrl = urlObj.site + urlObj.forgetPwd
      let codParam = {
        mobile: this.mobile, // 手机号
        code: this.codeVal, // 手机验证码
        newPwd: this.newPwd // 新密码
      }
      this.$axios.post(codUrl, codParam).then((res) => {
        if (res.msg === 'success') {
          this.$message({
            showClose: true,
            message: this.update_succ_jump_login,
            type: 'success'
          })
          window.clearTimeout(this.time) // 清除定时器
          setTimeout(() => {
            this.codeDisabled = false
            this.$router.push('/login')
          }, 3000)
        }
      })
    },
    // 重置
    reset () {
      this.mobile = ''
      this.codeVal = ''
      this.newPwd = ''
      this.confirmPwd = ''
    },
    // 帐号登录
    code_login () {
      window.clearTimeout(this.time) // 清除定时器
      sessionStorage.setItem('router', '/login')
      this.$router.push('/login')
    }
  }
}
</script>
<style lang="scss" scoped>
  .cont{
    box-sizing: border-box;
    height: 100%;
    min-height: 300px;
    min-width: 500px;
    background: #1ABC9C;
    overflow: auto;
    position: relative;
    .title-txt{
      position: absolute;
      height: 50px;
      /* border-bottom: 1px solid #C0C4CC; */
      top: 0px;
      left: 0px;
      right: 0px;
      line-height: 50px;
      color: #1ABC9C;
      text-align: center;
      .tab-logo{
        img{
          width: 180px;
          height: 30px;
          vertical-align: middle;
        }
      }
    }
    .login-box{
      width: 360px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -208px;
      margin-left: -210px;
      padding: 90px 30px 20px;
      background: white;
      border-radius: 10px;
      overflow: hidden;
      .title-box{
        font-size: 22px;
        /* color: white; */
        text-align: center;
        margin-bottom: 10px;
        letter-spacing: 2px;
      }
    }
  }
  .code-inp,.code-btn{
    width: 40%;
    float: left;
  }
  .code-btn{
    width: 56%;
    float: right;
  }
  .btn-box{
    white-space: nowrap;
    .el-button{
      width: 48%;
      margin-bottom: 15px;
    }
  }
  .txt-box{
    text-align: right;
    a{
      text-decoration: none;
      color: #1ABC9C;
      &:hover{
        text-decoration: underline;
      }
    }
  }
</style>
